import * as React from 'react';
import {
    Box,
    Container,
    List,
    ListItem,
    styled,
    Typography,
} from '@mui/material';
import { Button, Link } from 'gatsby-theme-material-ui';
import { Icon } from '@iconify/react';
import instagram from '@iconify/icons-mdi/instagram';
import twitter from '@iconify/icons-mdi/twitter';
import heroImage from '../../static/phone.png';
import moons from '../images/moons.svg';
import SEO from '../components/SEO';

const RootStyle = styled(Box)(() => ({
    background: 'linear-gradient(180deg, #EAFDCF, #A9C9FF);',
    minHeight: '100vh',
    position: 'relative',
    overflowY: 'auto',
    overflowX: 'hidden',
}));

const Hero = styled('img')(() => ({
    width: 320,
}));

const GlassCard = styled(Box)(({ theme }) => ({
    background: 'rgba(255, 255, 255, 0.3)',
    border: '1px solid rgba(215, 215, 215, 0.5)',
    backdropFilter: 'blur(6px)',
    borderRadius: 16,
    padding: 24,
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('lg')]: {
        '@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none))':
            {
                background: 'rgba(255, 255, 255, 0.8)',
            },
    },
}));

const LinkText = styled(Link)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    maxWidth: 126,
    marginBottom: 16,
    underline: 'none',
    color: '#EAFDCF',
    '&:hover': {
        color: theme.palette.primary.main,
    },
}));

const CustomContainer = styled(Container)(() => ({
    position: 'absolute',
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
}));

const MoonsContainer = styled(CustomContainer)(({ theme }) => ({
    '&:before': {
        position: 'absolute',
        content: `url(${moons})`,
        top: -200,
        width: 'calc(100% - 24px)',
    },
    [theme.breakpoints.down('lg')]: {
        '&:before': {
            top: -120,
        },
    },
    [theme.breakpoints.down('sm')]: {
        '&:before': {
            top: -20,
        },
    },
}));

const Star = styled(Box)(() => ({
    height: 275,
    width: 275,
    background: '#EAFDCF',
    filter: 'blur(48px)',
    borderRadius: '50%',
    position: 'absolute',
}));
const Star1 = styled(Star)(({ theme }) => ({
    left: 320,
    top: 380,
    [theme.breakpoints.down('lg')]: {
        left: 120,
        top: 320,
    },
    [theme.breakpoints.down('sm')]: {
        left: 'unset',
        right: -80,
    },
}));
const Star2 = styled(Star)(() => ({
    top: 700,
    left: -180,
}));
const Star3 = styled(Star)(({ theme }) => ({
    top: 600,
    right: 180,
    [theme.breakpoints.down('lg')]: {
        right: 60,
    },
    [theme.breakpoints.down('sm')]: {
        display: 'none',
    },
}));
const Star4 = styled(Star)(({ theme }) => ({
    top: 1072,
    right: 0,
    left: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
        marginRight: 0,
        left: 'unset',
        top: 1272,
        right: -80,
    },
}));

const SubsCard = styled(Box)(({ theme }) => ({
    borderRadius: 16,
    maxWidth: 466,
    marginLeft: 96,
    marginTop: 80,
    [theme.breakpoints.down('lg')]: {
        maxWidth: 410,
        marginLeft: 60,
        marginTop: 60,
    },
    [theme.breakpoints.down('md')]: {
        background: 'rgba(255, 255, 255, 0.3)',
        border: '1px solid rgba(215, 215, 215, 0.5)',
        backdropFilter: 'blur(6px)',
        marginLeft: 0,
        marginTop: 120,
        borderRadius: 16,
        padding: 16,
        '@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none))':
            {
                background: 'rgba(255, 255, 255, 0.8)',
            },
    },
}));
const Subtitle = styled(Typography)(({ theme }) => ({
    fontSize: 22,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
        textAlign: 'start',
    },
}));

const socialMedia = [
    {
        icon: instagram,
        link: 'https://instagram.com/gad_ishimwe',
    },
    {
        icon: twitter,
        link: 'https://twitter.com/gad_ishimwe',
    },
];

function MubaziPriceChecker(): JSX.Element {
    return (
        <>
            <SEO
                title="MUBAZI Price Checker"
                description="Have you ever wondered how much you'll have to
                            pay for a motorbike ride with a mubazi before you
                            start the journey? Well, now you can check it with Mubazi Price Checker."
                image="/preview.png"
            />
            <RootStyle>
                <Star1 />
                <Star2 />
                <Star3 />
                <Star4 />
                <MoonsContainer />
                <CustomContainer
                    sx={{
                        position: 'absolute',
                        top: 100,
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <Hero src={heroImage} alt="" />
                </CustomContainer>
                <CustomContainer sx={{ position: 'absolute' }}>
                    <Box pl={{ xs: 2, sm: 10 }} pt={4}>
                        <Typography
                            variant="h3"
                            component="h1"
                            fontFamily="Permanent Marker"
                            lineHeight={1}
                            letterSpacing={3}
                            color="primary"
                            fontSize={{ xs: 40 }}
                        >
                            Mubazi
                        </Typography>
                        <Typography
                            variant="h6"
                            fontFamily="Permanent Marker"
                            lineHeight={1}
                            color="primary"
                        >
                            Price checker
                        </Typography>
                    </Box>
                    <SubsCard>
                        <Subtitle>
                            Have you ever wondered how much you&apos;ll have to
                            pay for a motorbike ride with a mubazi before you
                            start the journey? Well, worry no more!😉
                        </Subtitle>
                    </SubsCard>
                </CustomContainer>
                <CustomContainer sx={{ top: 560, paddingBottom: 10 }}>
                    <GlassCard maxWidth={660} mb={8}>
                        <Typography fontSize={20}>
                            Currently, only Android is supported. But the iOS
                            version is coming soon as well. Come back and check
                            if it&apos;s added.
                        </Typography>
                        <Button
                            variant="contained"
                            sx={{
                                textTransform: 'none',
                                fontSize: 17,
                                fontWeight: 'bold',
                                marginTop: 3,
                                borderRadius: 4,
                                border: '1px solid #EAFDCF',
                                background:
                                    'linear-gradient(270deg, #9DB993 -26.56%, #285238 51.44%, #EAFDCF 139.84%)',
                                paddingX: 4,
                                height: 40,
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.25)',
                            }}
                            to="https://dl.dropboxusercontent.com/s/xw1yc8xbaxykya9/mubazi-price-checker.apk"
                        >
                            Download app
                        </Button>
                        <Typography mt={3} color="primary">
                            If you don&apos;t know how to install the app after
                            downloading it, check out this{' '}
                            <Link
                                to="https://www.wikihow.tech/Install-APK-Files-on-Android"
                                target="_blank"
                            >
                                guide to install APK files.
                            </Link>
                        </Typography>
                    </GlassCard>
                    <GlassCard maxWidth={900} mb={8}>
                        <Typography
                            variant="h5"
                            color="primary"
                            fontWeight="bold"
                        >
                            Usage instructions
                        </Typography>
                        <List sx={{ listStyleType: 'disc', paddingLeft: 3 }}>
                            <ListItem
                                disablePadding
                                sx={{ display: 'list-item' }}
                            >
                                <Typography fontSize={18}>
                                    Make sure you have internet connection.
                                </Typography>
                            </ListItem>
                            <ListItem
                                disablePadding
                                sx={{ display: 'list-item' }}
                            >
                                <Typography fontSize={18}>
                                    Make sure you&apos;re standing at the place
                                    of departure.
                                </Typography>
                            </ListItem>
                            <ListItem
                                disablePadding
                                sx={{ display: 'list-item' }}
                            >
                                <Typography fontSize={18}>
                                    Allow location permissions and enable
                                    location services on your phone if prompted
                                    because the price is calculated using your
                                    current position and provided address
                                    location.
                                </Typography>
                            </ListItem>
                        </List>
                        <Typography mt={3} color="primary">
                            NB: Prices will not be the same when you&apos;ve
                            taken a different path from the one used to
                            calculate the price. It might be high or low.
                        </Typography>
                    </GlassCard>
                    {socialMedia.map(({ icon, link }) => (
                        <LinkText
                            key={link}
                            target="_blank"
                            to={link}
                            underline="none"
                        >
                            <Icon icon={icon} fontSize={24} />
                            <Typography
                                component="span"
                                fontFamily="roboto"
                                ml={1}
                            >
                                gad_ishimwe
                            </Typography>
                        </LinkText>
                    ))}
                </CustomContainer>
            </RootStyle>
        </>
    );
}

export default MubaziPriceChecker;
