import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({
    title,
    description,
    image,
    article,
}: {
    title: string;
    description?: string;
    image?: string;
    article?: boolean;
}): JSX.Element {
    const { site } = useStaticQuery<GatsbyTypes.SEOQuery>(graphql`
        query {
            site {
                siteMetadata {
                    titleTemplate
                    defaultDescription: description
                    siteUrl: url
                    defaultImage: image
                    twitterUsername
                }
            }
        }
    `);

    const {
        titleTemplate,
        defaultDescription,
        siteUrl,
        defaultImage,
        twitterUsername,
    } = site.siteMetadata;

    const seo = {
        title,
        description: description ?? defaultDescription,
        image: image ?? defaultImage,
        url: `${siteUrl}`,
    };

    return (
        <Helmet title={seo.title} titleTemplate={titleTemplate}>
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.url + seo.image} />

            {/* {seo.url && <meta property="og:url" content={seo.url} />} */}

            {(article ? true : null) && (
                <meta property="og:type" content="article" />
            )}

            {seo.title && (
                <meta name="title" property="og:title" content={seo.title} />
            )}

            {seo.description && (
                <meta
                    name="description"
                    property="og:description"
                    content={seo.description}
                />
            )}

            {seo.image && (
                <meta
                    name="image"
                    property="og:image"
                    content={seo.url + seo.image}
                />
            )}

            <meta name="twitter:card" content="summary_large_image" />

            {twitterUsername && (
                <meta name="twitter:creator" content={twitterUsername} />
            )}

            {seo.title && <meta name="twitter:title" content={seo.title} />}

            {seo.description && (
                <meta name="twitter:description" content={seo.description} />
            )}

            {seo.image && (
                <meta name="twitter:image" content={seo.url + seo.image} />
            )}
        </Helmet>
    );
}

export default SEO;
