import * as React from 'react';
import { Container, List, ListItem, Typography } from '@mui/material';
import { Link } from 'gatsby-theme-material-ui';
import SEO from '../components/SEO';

// markup
function IndexPage(): JSX.Element {
    // const realNums = [400,411,421,432,443,454,464,475,486,496,507,518,528,539,550,561,571,582,593,603,614,625,635,646,657,668,678,689,700,710,721,732,742,753,764,775,785,796,807,817,828,839,849,860,871,882,892,903,914,924,935,946,956,967,978,989,999,1010,1021,1031,1042,1053,1063,1074,1085,1096,1106,1117,1128,1138,1149,1160,1170,1181,1192,1203,1213,1224 ];
    // const arr = [];
    // let num = 400;
    // for(let i=0; i<100;i++){
    //   arr.push(num);
    //   num = num + 10.7001;
    // }
    // return (
    //   <main >
    //     <h1>Hello</h1>
    //     <h3>{realNums.reduce((a, b) => a + b, 0)}</h3>
    //     {arr.map((nu,i) => <p key={i}>{`${Math.round(nu)} - ${Math.round(nu) === realNums[i]}`}</p>)}
    //   </main>
    // )

    return (
        <>
            <SEO title="MUBAZI Price Checker" />
            <Container>
                <h1>My apps</h1>
                <Typography>⚠️This page is still under construction</Typography>
                <List
                    sx={{ listStyleType: 'disc', paddingLeft: 3, marginTop: 3 }}
                >
                    <ListItem disablePadding sx={{ display: 'list-item' }}>
                        <Link to="/mubazi-price-checker">
                            MUBAZI Price Checker
                        </Link>
                    </ListItem>
                </List>
            </Container>
        </>
    );
}

export default IndexPage;

/**
 * 411    2.1km
 * 421
 * 432
 * 443
 * 454
 * 464
 * 475
 * 486
 * 496
 * 507
 * 518
 * 528
 * 539
 * 550
 * 561
 * 571
 * 582
 * 593
 * 603
 * 614
 * 625
 * 635
 * 646
 * 657
 * 668
 * 678
 * 689
 * 700
 * 710
 * 721
 * 732
 * 742
 * 753
 * 764
 * 775
 * 785
 * 796
 * 807
 * 817
 * 828
 * 839
 * 849
 * 860
 * 871
 * 882
 * 892
 * 903
 * 914
 * 924
 * 935
 * 946
 * 956
 * 967
 * 978
 * 989
 * 999
 * 1010
 * 1021
 * 1031
 * 1042
 * 1053
 * 1063
 * 1074
 * 1085
 * 1096
 * 1106
 * 1117
 * 1128
 * 1138
 * 1149
 * 1160
 * 1170
 * 1181
 * 1192
 * 1203
 * 1213
 * 1224  9.7km
 */
