import { createTheme } from '@mui/material';

const theme = createTheme({
    palette: {
        primary: {
            main: '#285238',
        },
        text: {
            primary: '#062013',
        },
    },
    typography: {
        fontFamily: 'Josefin Sans',
    },
});

export default theme;
